
const localVuexUser = {
    email: "giketyson@hackmetrix.com",
    // role: ["Cliente"],
    role: process.env.VUE_APP_ROLES,
    company_id: 32,
    company_name: "Hackmetrix",
    token: `Bearer ${process.env.VUE_APP_AUTH_TOKEN}`,
    name: "Aike",
    last_name: "Tyson",
    BusinessModule: {
        BusinessState: {    
            businessSelected: {
                company: {
                    frameworks: [
                        {
                            id: 2,
                            name: "ISO 27001",
                            status: "relieved",
                            available_snapshot: false,
                            selected: false,
                        },
                        {
                            id: 1,
                            name: "ISO 27002",
                            status: "relieved",
                            available_snapshot: false,
                            selected: false,
                        },
                    ],
                },
            },
        },
    },
};



export default localVuexUser;
